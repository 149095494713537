import styled, { css } from "styled-components";
import {
  SpacegroteskBoldWhite65px,
  InterBoldWhite16px,
  InterMediumWhite14px,
  InterWhite26px,
  InterWhite32px,
  InterSemiBoldWhite16px,
  SpacegroteskBoldWhite55px,
  ValignTextMiddle,
  InterWhite20px,
  InterSemiBoldWhite20px,
  InterWhite14px,
  BREAKPOINT_TABLET,
  TextStyle,
} from "./styledMixins";
import React, { useEffect } from "react";
import ShortReviews from "./ShortReviews";
import X1Row from "./X1Row";
import X1 from "./X1";
import Twitter from "./Twitter";
import { Blocks } from "./Blocks";
import { MembershipSection } from "./MembershipSection";
import { ComparisonTable } from "./ComparisonTable";
import { Faq } from "./Faq";
import Link from "next/link";
import YouTube from "@components/video/youtube";
import Icon, { IconType } from "@components/icon";
import { Rejections } from "./Rejections";
import Reviews from "./Reviews";
import { OptimizedImage } from "./OptimizedImage";
import { useAnalytics } from "@components/analytics";

const ContainerCenterHorizontal = styled.div`
  --amaranth: #e63946;
  --black: #000000;
  --black-2: #00000024;
  --coconut: #fefdff;
  --ebony-clay: #212838;
  --eerie-black: #1e1e1e;
  --licorice: #151515;
  --nobel: #b0b0b0;
  --seashell: #f1f1f1;
  --white: #ffffff;
  --white-2: #ffffff03;

  --font-size-l: 22px;
  --font-size-m: 20px;
  --font-size-s: 18px;
  --font-size-xl: 25px;
  --font-size-xs: 15px;
  --font-size-xxl: 32px;
  --font-size-xxxl: 55px;

  --font-family-inter: "Inter", Helvetica;
  --font-family-space_grotesk: "Space Grotesk", Helvetica;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: rgb(17, 24, 39);
  gap: 80px;

  .desktop-clean {
    align-items: center;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 80px;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
  }

  & strong {
    font-weight: 700;
  }
`;

function App() {
  const { trackEvent, trackPiwikEvent, trackPlausibleEvent } = useAnalytics();

  // useEffect(() => {
  //   let interval;
  //   let timeOnPage = 0;

  //   function setExponentialInterval(timeInSeconds) {
  //     interval = setInterval(() => {
  //       timeOnPage += timeInSeconds;
  //       trackPlausibleEvent("Read time", { time: `${timeOnPage}s` });
  //       trackPiwikEvent({ category: "Read time", action: `${timeOnPage}s` });

  //       if (timeOnPage === 5) {
  //         clearInterval(interval);
  //         setExponentialInterval(5);
  //       } else if (timeOnPage === 20) {
  //         clearInterval(interval);
  //         setExponentialInterval(10);
  //       } else if (timeOnPage === 60) {
  //         clearInterval(interval);
  //         setExponentialInterval(30);
  //       } else if (timeOnPage === 180) {
  //         clearInterval(interval);
  //         setExponentialInterval(60);
  //       } else if (timeOnPage === 480) {
  //         clearInterval(interval);
  //       }
  //     }, timeInSeconds * 1000);
  //   }

  //   setExponentialInterval(1);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <ContainerCenterHorizontal>
      <TopBar>
        <InnerTopBar>
          <Link href="/">
            <a>
              <Logo type={IconType.LogoSm} />
            </a>
          </Link>
          <Navigation>
            <Link href="#reviews">
              <NavItem
                onClick={() => trackEvent("Click", "Reviews Navigation")}
              >
                Reviews
              </NavItem>
            </Link>
            <Link href="#faq">
              <NavItem onClick={() => trackEvent("Click", "FAQs Navigation")}>
                FAQs
              </NavItem>
            </Link>
            <Link href="/project/react-job-simulator/welcome">
              <NavItem onClick={() => trackEvent("Click", "Signin Navigation")}>
                Sign In
              </NavItem>
            </Link>
            <Link href="#membership">
              <Button onClick={() => trackEvent("Click", "CTA Navigation")}>
                Get Access
              </Button>
            </Link>
          </Navigation>
        </InnerTopBar>
      </TopBar>
      <div className="desktop-clean screen">
        <X1Header>
          <Heading>
            <Copy>
              <Title>Your First React Job Starts Here</Title>
              <GetTheKnowledgeS>
                Get the knowledge, skills and experience you need to stand out
                from the crowd, coast through interviews and start getting
                offers with the <strong>React Job Simulator</strong>
              </GetTheKnowledgeS>
            </Copy>
            <CallToAction>
              <Link href="#membership">
                <Button1 onClick={() => trackEvent("Click", "CTA Hero")}>
                  Get Access
                </Button1>
              </Link>
              <Link href="#learn-more">
                <GetStarted1
                  onClick={() => trackEvent("Click", "Learn More Hero")}
                >
                  Learn More
                </GetStarted1>
              </Link>
            </CallToAction>
            <ShortReviews />
          </Heading>
        </X1Header>
        <X2Video>
          <YouTube id="gjiraAeToCI" />
        </X2Video>
        <X3ProblemIntro id="learn-more">
          <Text>
            <Headlineh156>
              Are you really ready to apply for your first React job?
            </Headlineh156>
            <Text0>
              If you've made it to this page, chances are you're looking for
              your first React job. The problem is the work you've done so far
              might not be enough to get started.
              <br />
              At any given moment there are thousands of new developers fighting
              for their first job and employers are demanding more and more for
              their junior roles. Just take a look at this job post:
            </Text0>
          </Text>
          <Image>
            <Jobpost2 src="jobpost_yLY1AE15c.png" alt="jobpost 2" />
          </Image>
          <Text01>
            Now if you can confidently say YES to every single one of these
            requirements and prove it in an interview, then congratulations! You
            really don’t need to stay on this page any more.
            <br />
            But if you can’t, then you need to face the truth...
          </Text01>
        </X3ProblemIntro>
        <X4ProblemAmplified>
          <Text1>
            <YourFirstReactJobStartsHere>
              You have no real-world experience
            </YourFirstReactJobStartsHere>
            <Text02>
              Many Junior developers are shocked to learn that working in a
              professional development team is completely different from
              building projects and applications on your own.
              <br />
              Online courses and tutorials are great for giving you the
              “know-how” to code, but they&#39;re unlikely to help you get and
              keep your first job.
              <br />
              So when you do work up the courage to apply, you’re most likely
              going to end up with rejections like this:
            </Text02>
          </Text1>
          <Rejections />
          <YourFirstReactJobStartsHere1>
            Polite but soul crushing after all the hard work you’ve put in to
            getting to this point. Here’s what these recruiters have failed to
            tell you...
            <br />
            <strong>
              You must find a way to stand out to start getting offers:
            </strong>
          </YourFirstReactJobStartsHere1>
        </X4ProblemAmplified>
        <JobOffers>
          <Image31 src="job-offer-02_ma_EbjZRb.png" alt="Job offer 2" />
          <Image31 src="job-offer-01_KYSjFKo_M.png" alt="Job offer 1" />
        </JobOffers>
        <X6SolutionWalkthrough>
          <TextTitle>
            <YourFirstReactJobStartsHere2>
              The React Job Simulator is a fast track to your first job
            </YourFirstReactJobStartsHere2>
            <GetTheKnowledgeS1>
              This first-of-its-kind program has been designed by an experienced
              Senior React Developer to give you the knowledge, skills and
              experience to help you land your first React job.
            </GetTheKnowledgeS1>
          </TextTitle>

          <Blocks />
        </X6SolutionWalkthrough>
        <WhoIsThisFor>
          <YourFirstReactJobStartsHere>
            <span className="spacegrotesk-bold-coconut-55px">
              Who is this for?
            </span>
          </YourFirstReactJobStartsHere>
          <X4Blocks>
            <X1Row
              frame17074783941Props={x1Row1Data.frame17074783941Props}
              frame17074783942Props={x1Row1Data.frame17074783942Props}
            />
            <X1Row
              frame17074783941Props={x1Row2Data.frame17074783941Props}
              frame17074783942Props={x1Row2Data.frame17074783942Props}
            />
          </X4Blocks>
        </WhoIsThisFor>
        <X8AntiTarget>
          <Frame1707478401>
            <YourFirstReactJobStartsHere2>
              <span className="spacegrotesk-bold-coconut-55px">
                WAIT! Before you continue, I want to explain who this is NOT
                for..
              </span>
            </YourFirstReactJobStartsHere2>
            <X1Row
              className="frame-1707478400"
              frame17074783941Props={x1Row3Data.frame17074783941Props}
              frame17074783942Props={x1Row3Data.frame17074783942Props}
            />
          </Frame1707478401>
          <Headlineh1561>
            This program will help you, <strong>but not right now</strong>. It
            is a challenging program designed to prepare you for the kind of
            experience you will have as a Junior React Developer and by the end
            you’ll have the confidence to sell yourself during interviews and
            the skills you need to effortlessly integrate yourself into an
            experienced team’s workflow.
          </Headlineh1561>
        </X8AntiTarget>

        <Reviews title="Join 100s of Happy Members" id="reviews" />

        <X10Process>
          <YourFirstReactJobIs3StepsAway>
            <span>
              <span className="spacegrotesk-bold-coconut-55px">
                Your first React Job is 3 steps away
              </span>
            </span>
          </YourFirstReactJobIs3StepsAway>
          <Blocks1>
            <X1 text22Props={x11Data.text22Props} number="01" />
            <X1 text22Props={x12Data.text22Props} number="02" />
            <X1 text22Props={x13Data.text22Props} number="03" />
          </Blocks1>
        </X10Process>
        <X11Instructor>
          <MeetYourInstructor>
            <span>
              <span className="spacegrotesk-bold-coconut-55px">
                Meet your instructor
              </span>
            </span>
          </MeetYourInstructor>
          <InfoAvatart>
            <Group1261153017
              src="johannes-kettmann-portrait_3KNIhYhml.jpeg"
              alt="Group 1261153017"
            />
            <ImJohannesASelf>
              I’m Johannes, a self-taught developer with over 10 years of
              professional experience. I&#39;ve been working with React since
              January 2016 and throughout my career, I’ve worked with a variety
              of teams in both small startups and large enterprises. This has
              given me invaluable insights into different working environments
              and development practices.
              <br />
              <br />
              The React Job Simulator is the kind of program I wish existed when
              I was starting my journey. Back then, I had no exposure to
              professional developers, no one to compare myself to, and no idea
              about best practices or go-to tools.
              <br />
              <br />
              This lack of guidance and knowledge became painfully obvious
              during my first few job interviews. It took me years to get to a
              point where I could join a new team and immediately adopt their
              tools, workflows and processes. But with the knowledge I have now
              I comfortably make six figures a year and take several months off
              to spend time with my family.
              <br />
              <br />
              So when I say I understand the challenges you face, I mean it. And
              I’ve designed this program to help you skip several years of
              learning and confidently navigate the job market to land your
              first React job.
            </ImJohannesASelf>
          </InfoAvatart>
        </X11Instructor>
        <MembershipSection />
        <X13Comparison>
          <YourFirstReactJobStartsHere6>
            <span className="spacegrotesk-bold-coconut-55px">
              How does this compare?
            </span>
          </YourFirstReactJobStartsHere6>
          <ComparisonTable />
        </X13Comparison>

        <Reviews title="But don't just take my word for it..." id="reviews" />

        <X16TechStack>
          <YourFirstReactJobStartsHere7>
            <span className="spacegrotesk-bold-coconut-55px">
              Technology you&#39;ll work with
            </span>
          </YourFirstReactJobStartsHere7>
          <img src="/img/techstack.svg" />
        </X16TechStack>
        <X17FAQ id="faq">
          <Questions>
            <span className="spacegrotesk-bold-coconut-55px">Questions?</span>
          </Questions>
          <Faq />
        </X17FAQ>
      </div>
      <X18SummaryCTA>
        <SummaryInner>
          <YourFirstReactJobStartsHere8>
            <span className="spacegrotesk-bold-coconut-55px">
              Ready to get the knowledge, skills and experience that actually
              impress Senior Developers?
            </span>
          </YourFirstReactJobStartsHere8>
          <Link href="#membership">
            <Button4 onClick={() => trackEvent("Click", "CTA Bottom")}>
              YES! Get Access
            </Button4>
          </Link>
          <ShortReviews />
        </SummaryInner>
      </X18SummaryCTA>
      <X19Footer>
        <Logo1 src="/img/logo-2@2x.png" alt="Logo" />
        <Twitter />
        <Info6>
          <Frame1707478417>
            <PrivacyPolicy>privacy policy</PrivacyPolicy>
            <TermsAndConditions>terms and conditions</TermsAndConditions>
            <PrivacyPolicy as="a" href="https://office-walker.com">
              Improve your developer health
            </PrivacyPolicy>
          </Frame1707478417>
          <Policies>© 2023 ooloo software UG - All rights reserved</Policies>
        </Info6>
        <Disclaimer>
          Disclaimer: The React Job Simulator provides training and resources
          with the aim of enhancing coding skills. While we strive to replicate
          a real job environment, we make no guarantees regarding specific
          outcomes, job placement, or success in external assessments.
          Individual effort, dedication, and other factors play a crucial role
          in achieving results. Our programs are designed to equip junior
          developers with skills, but ultimate success depends on various
          factors beyond our control. By participating in our program, you
          acknowledge and accept that results may vary, and Profy.dev is not
          responsible for any specific career outcomes.
        </Disclaimer>
      </X19Footer>
    </ContainerCenterHorizontal>
  );
}

export default App;

const X1Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 49px;
`;

const TopBar = styled.div`
  width: 100%;
  box-shadow: 0px 4px 30px #0000000a;
  backdrop-filter: blur(31px) brightness(100%);
  -webkit-backdrop-filter: blur(31px) brightness(100%);
  background-color: rgba(17, 24, 39, 0.8);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const InnerTopBar = styled.div`
  width: 100%;
  height: 60px;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  padding: 0 8px;
  align-items: center;
  gap: 10px;

  @media (${BREAKPOINT_TABLET}) {
    height: 80px;
    padding: 0 18px;
  }
`;

const Logo = styled(Icon)`
  width: 40px;
  object-fit: cover;

  @media (${BREAKPOINT_TABLET}) {
    width: 60px;
  }
`;

const Navigation = styled.div`
  ${InterBoldWhite16px}
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const NavItem = styled.a`
  ${ValignTextMiddle}
  ${InterMediumWhite14px}
  height: 24px;
  white-space: nowrap;
  padding: 0 6px;
  cursor: pointer;

  @media (${BREAKPOINT_TABLET}) {
    font-size: 16px;
    padding: 0 10px;
  }
`;

const Button = styled.a`
  ${ValignTextMiddle}
  ${InterSemiBoldWhite16px}
  display: flex;
  align-items: center;
  background-color: var(--amaranth);
  border-radius: 5px;
  box-shadow: 3px 3px 0px #ffffff;
  height: 36px;
  margin-left: 12px;
  padding: 0 12px;
  white-space: nowrap;
  cursor: pointer;

  @media (${BREAKPOINT_TABLET}) {
    height: 48px;
    margin-left: 24px;
    padding: 0 24px;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 66px;
  padding: 0 20px;
`;

const Copy = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 179px;
  gap: 19px;
`;

const Title = styled.h1`
  ${SpacegroteskBoldWhite65px}
  width: 100%;
  text-align: center;
`;

const GetTheKnowledgeS = styled.p`
  ${InterWhite32px}
  width: 100%;
  min-height: 100px;
  text-align: center;
  letter-spacing: 0;
`;

const CallToAction = styled.div`
  width: 228px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 124px;
  gap: 21px;
`;

const Button1 = styled.a`
  ${InterSemiBoldWhite20px}
  height: 60px;
  display: flex;
  padding: 0 57px;
  align-items: center;
  min-width: 228px;
  background-color: var(--amaranth);
  border-radius: 5px;
  box-shadow: 3px 3px 0px #ffffff;
  cursor: pointer;
`;

const GetStarted1 = styled.a`
  ${InterSemiBoldWhite20px}
  margin-top: 30px;
  text-decoration: underline;
  cursor: pointer;
`;

const X2Video = styled.div`
  width: 100%;
  max-width: 817px;
`;

const X3ProblemIntro = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 0 20px;
  scroll-margin-top: 100px;
`;

const Text = styled.div`
  ${TextStyle}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 317px;
  gap: 19px;
`;

const Headlineh156 = styled.p`
  ${SpacegroteskBoldWhite55px}
  width: 100%;
  text-align: center;
  letter-spacing: 0;
`;

const Text0 = styled.div`
  ${InterWhite20px}
  width: 100%;
  position: relative;
  text-align: center;
`;

const Image = styled.div`
  width: 100%;
  max-width: 908px;
  margin-top: 29px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2.767256498336792px 2.2138051986694336px #00000005,
    0px 6.650102138519287px 5.32008171081543px #00000007,
    0px 12.521552085876465px 10.017241477966309px #00000009,
    0px 22.3363094329834px 17.869047164916992px #0000000b,
    0px 41.777610778808594px 33.422088623046875px #0000000d,
    0px 100px 80px #00000012;
`;

const Jobpost2 = styled(OptimizedImage)`
  width: 100%;
  max-width: 100%;
`;

const Text01 = styled.div`
  ${TextStyle}
  ${InterWhite26px}
  width: 100%;
  position: relative;
  margin-top: 40px;
  text-align: center;
`;

const X4ProblemAmplified = styled.div`
  ${TextStyle}
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  gap: 28px;
`;

const YourFirstReactJobStartsHere = styled.p`
  ${SpacegroteskBoldWhite55px}
  ${TextStyle}
  text-align: center;
`;

const Text02 = styled.div`
  ${InterWhite20px}
  width: 100%;
  position: relative;
  text-align: center;
`;

const YourFirstReactJobStartsHere1 = styled.p`
  ${InterWhite20px}
  ${TextStyle}
  margin-top: 40px;
  text-align: center;
  padding: 0 20px;
`;

const JobOffers = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  flex-direction: column;
  gap: 20px;
`;

const Image31 = styled(OptimizedImage)`
  width: 100%;
  max-width: 900px;
  object-fit: cover;
  border-radius: 20px;
`;

const X6SolutionWalkthrough = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 4191px;
  gap: 61px;
`;

const TextTitle = styled.div`
  ${TextStyle}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  gap: 31px;
`;

const YourFirstReactJobStartsHere2 = styled.p`
  ${TextStyle}
  ${SpacegroteskBoldWhite55px}
  width: 100%;
  text-align: center;
`;

const GetTheKnowledgeS1 = styled.p`
  ${InterWhite20px}
  width: 100%;
  text-align: center;
`;

const WhoIsThisFor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  padding: 0 20px;
`;

const X4Blocks = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;
`;

const X8AntiTarget = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 77px;
  padding: 0 20px;
`;

const Frame1707478401 = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 41px;
`;

const Headlineh1561 = styled.p`
  ${TextStyle}
  ${InterWhite20px}
  width: 100%;
  text-align: center;
`;

const YourFirstReactJobStartsHere4 = styled.p`
  ${SpacegroteskBoldWhite55px}
  width: 100%;
  text-align: center;
`;

const X10Process = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 51px;
`;

const YourFirstReactJobIs3StepsAway = styled.p`
  ${ValignTextMiddle}
  ${SpacegroteskBoldWhite55px}
            width: 100%;
  text-align: center;
  padding: 0 20px;
`;

const Blocks1 = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 26px;
  row-gap: 100px;
  padding: 40px 20px;
`;

const X11Instructor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 150px;
  padding: 0 20px;
`;

const MeetYourInstructor = styled.div`
  ${ValignTextMiddle}
  ${SpacegroteskBoldWhite55px}
            width: 100%;
  text-align: center;
  letter-spacing: 0;
`;

const InfoAvatart = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 44px;
  background-color: var(--ebony-clay);
  border-radius: 10px;
  box-shadow: -34.8426399230957px 104.52791595458984px 174.21319580078125px
    #1f1c550d;
  padding: 20px;
  position: relative;
`;

const Group1261153017 = styled(OptimizedImage)`
  width: 150px;
  margin-top: -95px;
  border-radius: 50%;
  @media (${BREAKPOINT_TABLET}) {
    margin-top: -145px;
    width: 250px;
  }
`;

const ImJohannesASelf = styled.p`
  ${ValignTextMiddle}
  ${InterWhite20px}
  width: 100%;
  text-align: center;
`;

const X13Comparison = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 10px;
`;

const YourFirstReactJobStartsHere6 = styled.div`
  ${SpacegroteskBoldWhite55px}
  width: 100%;
  text-align: center;
`;

const X16TechStack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  gap: 50px;
  overflow: hidden;
`;

const YourFirstReactJobStartsHere7 = styled.div`
  ${SpacegroteskBoldWhite55px}
  width: 100%;
  text-align: center;
`;

const X17FAQ = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 51px;
  scroll-margin-top: 100px;
`;

const Questions = styled.div`
  ${SpacegroteskBoldWhite55px}
  width: 100%;
  text-align: center;
`;

const X18SummaryCTA = styled.div`
  width: 100%;
  padding: 44px 20px;
  box-shadow: 50px 50px 100px #00000012;
  background: linear-gradient(
    180deg,
    rgb(34.00000177323818, 158.00000578165054, 188.0000039935112) 0%,
    rgb(230.00000149011612, 57.00000040233135, 70.00000342726707) 100%
  );
`;

const SummaryInner = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const YourFirstReactJobStartsHere8 = styled.p`
  ${SpacegroteskBoldWhite55px}
  width: 100%;
  text-align: center;
`;

const Button4 = styled.a`
  ${InterSemiBoldWhite20px}
  height: 60px;
  margin-top: 33px;
  margin-bottom: 60px;
  display: flex;
  padding: 0 57px;
  align-items: center;
  background-color: var(--amaranth);
  border-radius: 5px;
  box-shadow: 3px 3px 0px #ffffff;
  cursor: pointer;
`;

const X19Footer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding-bottom: 20px;
`;

const Logo1 = styled.img`
  width: 80px;
  height: 80px;
`;

const Info6 = styled.div`
  ${InterMediumWhite14px}
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60px;
  gap: 11px;
`;

const Frame1707478417 = styled.div`
  height: 24px;
  display: flex;
  align-items: flex-start;
  gap: 25px;
`;

const PrivacyPolicy = styled.div`
  ${InterWhite14px}
`;

const TermsAndConditions = styled.div`
  ${InterWhite14px}
`;

const Policies = styled.p`
  ${InterWhite14px}
`;

const Disclaimer = styled.p`
  ${InterWhite14px}
  margin-top: 20px;
  padding: 0 20px;
`;

const frame17074783941Data = {
  materialSymbolsCheckCircleOutlineRo:
    "/img/material-symbols-check-circle-outline-rounded-5@2x.png",
  spanText1: "Computer Science Students",
  spanText2:
    "If you’re a student or recent graduate looking for your first job, you’ll get the skills to stand out in interviews.",
  className: "",
};

const frame17074783942Data = {
  materialSymbolsCheckCircleOutlineRo:
    "/img/material-symbols-check-circle-outline-rounded-6@2x.png",
  spanText1: "Self-taught Developers",
  spanText2:
    "You’ll go from tutorial hell to creating “production-ready” code using professional tools and workflows.",
  className: "",
};

const x1Row1Data = {
  frame17074783941Props: frame17074783941Data,
  frame17074783942Props: frame17074783942Data,
};

const frame17074783943Data = {
  materialSymbolsCheckCircleOutlineRo:
    "/img/material-symbols-check-circle-outline-rounded-5@2x.png",
  spanText1: "Bootcamp Graduates",
  spanText2:
    "You’ll gain valuables skills and experience to improve your chances of landing your first job.",
  className: "",
};

const frame17074783944Data = {
  materialSymbolsCheckCircleOutlineRo:
    "/img/material-symbols-check-circle-outline-rounded-6@2x.png",
  spanText1: "Solo Developers",
  spanText2:
    "If you’re an experienced developer without team experience, you’ll learn how to work as part of a team.",
  className: "",
};

const x1Row2Data = {
  frame17074783941Props: frame17074783943Data,
  frame17074783942Props: frame17074783944Data,
};

const frame17074783945Data = {
  materialSymbolsCheckCircleOutlineRo: "/img/ion-close-circle-sharp-4@2x.png",
  spanText1: "Beginners",
  spanText2:
    "You’ll need an understanding of React, JavaScript, HTML & CSS and ideally you should have built a small project on your own as well.",
  className: "frame-1707478394-2",
};

const frame17074783946Data = {
  materialSymbolsCheckCircleOutlineRo:
    "/img/mingcute-close-circle-line-2@2x.png",
  spanText1: "Tutorial Junkies",
  spanText2:
    "If you’re looking for your next tutorial or expect a lot of “handholding” with step by step instructions, this program is not for you.",
  className: "frame-1707478395-2",
};

const x1Row3Data = {
  frame17074783941Props: frame17074783945Data,
  frame17074783942Props: frame17074783946Data,
};

const text221Data = {
  choice: "/img/choice-1@2x.png",
  spanText: "Choose a membership",
};

const x11Data = {
  text22Props: text221Data,
};

const text222Data = {
  choice: "/img/ewallet-1@2x.png",
  spanText: "Complete your payment",
};

const x12Data = {
  text22Props: text222Data,
};

const x13Data = {
  text22Props: {
    choice: "/img/premium-quality--1--1-2@2x.png",
    spanText: "Join members area",
  },
};
